import './App.css'
import ExplanationText from './ExplanationText'
import NoResult from './NoResult'
import React, { useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import zonesArray from './zones.json'
import countriesArray from './countries.json'
import sitesArray from './sites.json'

function App () {
  const [zoneSelected, setZoneSelected] = useState('')
  const [countrySelected, setCountrySelected] = useState('')
  const [countries, setCountries] = useState([])
  const [sites, setSites] = useState([])

  const handleZoneChange = (event) => {
    setZoneSelected(event.target.value)
    setCountrySelected('')
    setCountries(countriesArray.filter(item => item.zone === event.target.value))
    setSites([])
  }

  const handleCountryChange = (event) => {
    setCountrySelected(event.target.value)
    setSites(sitesArray.filter(item => item.country === event.target.value).sort((a, b) => {
      return a.city.localeCompare(b.city)
    }))
  }

  const zonesMapped = zonesArray.map(item => (
    <MenuItem key={ item.id } value={ item.id }>{ item.label }</MenuItem>
  ))

  const countriesMapped = countries.map(item => (
    <MenuItem key={ item.id } value={ item.id }>{ item.label }</MenuItem>
  ))

  const sitesMapped = sites.map((item, index) => (
    <tr key={ item.id }>
      <th className="align-center phone-hidden">{ index + 1 }</th>
      <td>{ item.city }</td>
      <td>
        <a href={ item.url } target="_blank" rel="noreferrer">
          { item.url }
        </a>
      </td>
      <td>{ item.lang }</td>
    </tr>
  ))

  /*
  const zonesMapped = useMemo(() => (
    zonesArray.map(item => (
      <MenuItem key={ item.id } value={ item.id }>{ item.label }</MenuItem>
    ))
  ), [])

  const countriesMapped = useMemo(() => (
    countries.filter(item => item.zone === zoneSelected).map(item => (
      <MenuItem value={ item.id }>{ item.label }</MenuItem>
    ))
  ), [zoneSelected])

  const sitesMapped = useMemo(() => (
    sites.filter(item => item.country === countrySelected).map(item => (
      <tr key={ item.id }>
        <th>{ item.id }</th>
        <td>{ item.city }</td>
        <td className="td-align-left">
          <a href={ item.url } target="_blank">
            { item.url }
          </a>
        </td>
        <td>{ item.lang }</td>
      </tr>
    ))
  ), [countrySelected])
  */
  
  const siteUrl = document.location.href.replace('registres-internationaux-pp', 'www').replace('registres-internationaux', 'www')
  const imgUrl = 'logos-greffe/logo-greffe-' + getGreffeCodeFromSiteUrl(siteUrl) + '.png'

  function getGreffeCodeFromSiteUrl (siteUrl) {
    if (/greffe-tc-paris/.test(siteUrl)) return '7501'
    if (/greffe-tc-lillemetropole/.test(siteUrl)) return '5910'
    if (/greffe-tc-auxere/.test(siteUrl)) return '8901'
    if (/greffe-tc-sedan/.test(siteUrl)) return '0802'
    if (/greffe-tc-tours/.test(siteUrl)) return '3701'
    if (/greffe-tc-saintnazaire/.test(siteUrl)) return '4402'
    if (/greffe-tc-limoges/.test(siteUrl)) return '8701'
    if (/greffe-tc-meaux/.test(siteUrl)) return '7701'
    if (/greffe-tc-beauvais/.test(siteUrl)) return '6001'
    if (/greffe-tc-larochesuryon/.test(siteUrl)) return '8501'
    if (/greffe-tc-cayenne/.test(siteUrl)) return '9731'
    if (/greffe-tc-poitiers/.test(siteUrl)) return '8602'
    if (/greffe-tc-chalonsenchampagne/.test(siteUrl)) return '5101'
    if (/greffe-tc-saintquentin/.test(siteUrl)) return '0202'
    if (/greffe-tc-nevers/.test(siteUrl)) return '5802'
    if (/greffe-tc-saint-pierre/.test(siteUrl)) return '9742'
    if (/gtc972/.test(siteUrl)) return '9721'
    if (/greffe-tc-soissons/.test(siteUrl)) return '0203'
    if (/greffe-tc-orleans/.test(siteUrl)) return '4502'
    if (/greffe-tc-nantes/.test(siteUrl)) return '4401'
    if (/greffe-tc-valenciennes/.test(siteUrl)) return '5906'
    if (/greffe-tc-libourne/.test(siteUrl)) return '3303'
    return '0000'
  }

  return (
    <div className="app">
      <header className="app-header">
        <div className="container">
          <a href={ siteUrl }>
            <img src={ imgUrl } className="app-logo" alt="logo" />
          </a>
        </div>
      </header>
      <main>
        <div className="main-header">
          <div className="container">
            <h1>Registres internationaux</h1>
            <div className="selects">
              <FormControl variant="filled" sx={{ minWidth: 200 }}>
                <InputLabel id="zone-geographique-label">Zone géographique</InputLabel>
                <Select
                  labelId="zone-geographique-label"
                  id="zone-geographique"
                  value={ zoneSelected }
                  onChange={ handleZoneChange }
                >
                  { zonesMapped }
                </Select>
              </FormControl>
              <FormControl variant="filled" sx={{ minWidth: 200 }}>
                <InputLabel id="pays-label">Pays</InputLabel>
                <Select
                  labelId="pays-label"
                  id="pays"
                  value={ countrySelected }
                  onChange={ handleCountryChange }
                >
                  { countriesMapped }
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <section className="container text-center main-table-container">
          { !countrySelected && <ExplanationText /> }
          { countrySelected && sitesMapped.length === 0 && <NoResult /> }
          { countrySelected && sitesMapped.length > 0 &&
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="align-center phone-hidden">#</th>
                  <th scope="col">Ville ou région</th>
                  <th scope="col">Site</th>
                  <th scope="col">Langue</th>
                </tr>
              </thead>
              <tbody>
                { sitesMapped }
              </tbody>
            </table>
          }
        </section>
      </main>
    </div>
  )
}

export default App
